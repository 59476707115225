<template>
  <div class="checkout-items">
    <b-card
      v-for="product in products"
      :key="product.id"
      class="ecommerce-card"
      no-body
    >
      <!-- Product Image -->
      <div class="item-img p-1">
        <b-link>
          <b-img :src="product.image_url" :alt="`${product.name}-${product.id}`" />
        </b-link>
      </div>

      <!-- Product Details: Card Body -->
      <b-card-body>
        <div class="item-name">
          <h6 class="mb-0">
            <b-link class="text-body">
              {{ product.name }}
            </b-link>
          </h6>
          <span class="item-company"
            >By <b-link class="company-name">{{ product.brand }}</b-link></span
          >
        </div>
        <div class="item-description mt-1" v-html="product.description">
           {{ product.description }}
        </div>
      </b-card-body>

      <!-- Product Options/Actions -->
      <div class="item-options text-center">
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">${{ product.price }}</h4>
          </div>
        </div>
        <b-button
          variant="light"
          class="mt-1 remove-wishlist"
          @click="removeProductFromCartClick(product)"
        >
          <feather-icon icon="XIcon" class="mr-50" />
          <span>Remove</span>
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BLink,
  BImg,
  BButton,
  BBadge,
  BFormSpinbutton,
} from "bootstrap-vue";
import store from "@/store";
import { ref } from "@vue/composition-api";
import { formatDate } from "@core/utils/filter";
import { useEcommerce, useEcommerceUi } from "../useEcommerce";

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BBadge,
    BFormSpinbutton,
  },
  setup() {
    const products = ref([]);

    const { toggleProductInWishlist } = useEcommerceUi();
    const { removeProductFromCart } = useEcommerce();
    const removeProductFromCartClick = (product) => {
      removeProductFromCart(product.product_id).then(() => {
        const productIndex = products.value.findIndex(
          (p) => p.product_id === product.product_id
        );
        products.value.splice(productIndex, 1);

        store.commit(
          "app-ecommerce/UPDATE_CART_ITEMS_COUNT",
          products.value.length
        );
      });
    };

    // const fetchCartProducts = () => {
    //   store.dispatch('app-ecommerce/fetchCartProducts')
    //     .then(response => {
    //       = response.data.products
    //     })
    // }
    // fetchCartProducts()

    products.value = store.state["app-ecommerce"].cartItems;

    return {
      products,

      // UI
      toggleProductInWishlist,
      removeProductFromCartClick,

      // Filter
      formatDate,
    };
  },
};
</script>

<style>
</style>
